<template>
  <div class="inner">
    <van-popup v-model="show" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        :confirm-button-text="$t('index.confirm')"
        :cancel-button-text="$t('index.cancel')"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <!-- 页面样式 -->
    <div class="choose-title" @click="showDia">
      <span>{{ voteStatus }}</span>
      <div class="icon"></div>
    </div>

    <votelist
      :voteMsg="voteMsg"
      type="0"
      @fatherMea="fatherMea"
      :total="total"
      :isReset="isReset"
    ></votelist>
  </div>
</template>

<script>
import votelist from "@/components/voteList";
import { voteDetailList } from "@/api/detail";
export default {
  components: {
    votelist,
  },
  data() {
    return {
      voteStatus: "",
      show: false,
      columns: ["赞成", "反对", "弃权", "全部"],
      voteMsg: [],
      voteKind: "all",
      total: 0,
      isReset: false,
    };
  },
    created() {
    // voteDetailList();
    this.voteStatus=this.$t('index.please_select_voting_status');
    this.columns[0] = this.$t('index.agree');
    this.columns[1] = this.$t('index.disagree');
    this.columns[2] = this.$t('index.abstain');
    this.columns[3] = this.$t('index.all');

  },
  methods: {
    onConfirm(msg) {
      console.log(msg);
      if (msg == this.$t('index.disagree')) {
        this.voteKind = "-1";
      } else if (msg ==  this.$t('index.agree')) {
        this.voteKind = "1";
      } else if (msg == this.$t('index.all')) {
        this.voteKind = "all";
      } else if (msg == this.$t('index.abstain')) {
        this.voteKind = "0";
      }
      this.show = false;
      this.voteStatus = msg;
      this.isReset = true;
      this.voteMsg = [];
    },
    // 出现选择框
    showDia() {
      this.show = true;
    },
    async getVoteList(start) {
      let obj = {};
      obj.id = this.$route.query.id;
      obj.vote_type = this.voteKind;
      obj.start = start;
      obj.limit = "4";
      let res = await voteDetailList(obj);
      this.total = res.total;
      this.isReset = false;
      this.voteMsg.push(...res.data);
    },
    // 子组件调用的加载方法
    fatherMea(num) {
      this.getVoteList(num);
    },
  }
};
</script>

<style lang="less" scoped>
.inner {
  padding-top: 16px;
  .choose-title {
    display: flex;
    height: 19px;
    width: 89.568%;
    margin: 0 auto;
    height: 19px;
    font-size: 14px;
    color: #181a33;
    line-height: 19px;
    font-weight: bold;
    .icon {
      border-right: 2.4px solid #181a33;
      border-top: 2.4px solid #181a33;
      height: 10px;
      width: 10px;
      transform: rotate(133deg);
      border-left: 2px solid transparent;
      border-bottom: 2px solid transparent;
      box-sizing: border-box;
      margin-left: 11px;
      vertical-align: -3px;
    }
  }
}
</style>